import { KinesisClient, PutRecordsCommand } from '@aws-sdk/client-kinesis';

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();

    const kinesisClient = new KinesisClient({
        region: config.public.kinesis.region,
        credentials: {
            accessKeyId: config.public.kinesis.accessKeyId,
            secretAccessKey: config.public.kinesis.secretAccessKey,
        },
    });

    const putEventToKinesis = async (events: any[]) => {
        try {
            const params = {
                StreamName: config.public.kinesis.streamName,
                Records: events.map((event) => ({
                    Data: JSON.stringify(event),
                    PartitionKey: 'web-group',
                })),
            };

            const command = new PutRecordsCommand(params);
            const response = await kinesisClient.send(command);

            console.log('Kinesis Response:', response);
        } catch (error) {
            console.error('Error sending data to Kinesis:', error);
        }
    };

    return {
        provide: {
            kinesis: {
                putEventToKinesis,
            },
        },
    };
});