import revive_payload_client_4sVQNw7RlN from "/var/www/fictionme-web/html/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/fictionme-web/html/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/fictionme-web/html/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/fictionme-web/html/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/fictionme-web/html/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/fictionme-web/html/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/fictionme-web/html/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/fictionme-web/html/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/fictionme-web/html/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/fictionme-web/html/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/var/www/fictionme-web/html/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_w2VlvAEcpa from "/var/www/fictionme-web/html/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_ghbUAjaD3n from "/var/www/fictionme-web/html/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/var/www/fictionme-web/html/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import amplitude_client_a5NDjvTWdZ from "/var/www/fictionme-web/html/plugins/amplitude.client.ts";
import api_fetch_pgUE5BQVym from "/var/www/fictionme-web/html/plugins/api.fetch.ts";
import auth_vT9JWWT9pN from "/var/www/fictionme-web/html/plugins/auth.ts";
import kinesis_client_TISaVkDk2n from "/var/www/fictionme-web/html/plugins/kinesis.client.ts";
import loader_hdpS77Ubg5 from "/var/www/fictionme-web/html/plugins/loader.ts";
import toast_ysMjUcU7eJ from "/var/www/fictionme-web/html/plugins/toast.ts";
import visitor_56ypWAcerZ from "/var/www/fictionme-web/html/plugins/visitor.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_w2VlvAEcpa,
  plugin_ghbUAjaD3n,
  plugin_client_OVoKJro5pc,
  amplitude_client_a5NDjvTWdZ,
  api_fetch_pgUE5BQVym,
  auth_vT9JWWT9pN,
  kinesis_client_TISaVkDk2n,
  loader_hdpS77Ubg5,
  toast_ysMjUcU7eJ,
  visitor_56ypWAcerZ
]