import * as amplitude from '@amplitude/analytics-browser';
import { useDevice } from "~/composible/useDevice";
import { useAuth } from "~/composible/useAuth";

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const { udid } = useDevice();
    const { user } = useAuth()

    amplitude.init(config.public.amplitude.apiKey);

    amplitude.setUserId(user.value?.uuid)
    amplitude.setDeviceId(udid.value)

    return {
        provide: {
            amplitude,
        },
    };
});