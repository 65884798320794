import { useDevice } from "~/composible/useDevice";
import type { IUser, ITokens } from "~/types/user";

export const useAuth = () => {
    const { $apiFetch, $amplitude } = useNuxtApp();

    const isAuthModalShow = useState('is-auth-modal-show', () => false);
    const authUser = useState<IUser|null>('auth-user', () => null);
    const totalBalance = computed(() => {
        return authUser.value
            ? authUser.value.balance.coins + authUser.value.balance.bonus
            : 0
    });

    const authToken = useCookie<string|null>('auth-token')
    const refreshToken = useCookie<string|null>('refresh-token')
    const { udid, timezone, platformName, platformVersion, appVersion } = useDevice();

    const setUser = (user: IUser | null) => {
        authUser.value = user;
    };

    const setTokens = (tokens: ITokens | null) => {
        authToken.value = tokens?.access_token || null;
        refreshToken.value = tokens?.refresh_token || null;
    };

    const register = async (email: string, password: string) => {
        const data:{ data: {user: IUser, tokens: ITokens }} = await $apiFetch("/auth/register", {
            method: "POST",
            body: {
                email,
                password,
                udid: udid.value,
                timezone: timezone.value,
                app_version: appVersion.value,
                platform_name: platformName.value,
                platform_version: platformVersion.value,
            },
        });

        setUser(data.data.user);
        setTokens(data.data.tokens);

        $amplitude.setUserId(data.data.user.uuid);

        return authUser;
    };

    const login = async (email: string, password: string) => {
        const data:{ data: { user: IUser, tokens: ITokens } } = await $apiFetch("/auth/login", {
            method: "POST",
            body: {
                email,
                password,
                udid: udid.value,
                timezone: timezone.value,
                app_version: appVersion.value,
                platform_name: platformName.value,
                platform_version: platformVersion.value,
            },
        });

        setUser(data.data.user);
        setTokens(data.data.tokens);

        $amplitude.setUserId(data.data.user.uuid);

        return authUser;
    };

    interface SocialsData {
        identifier: string;
        email?: string|null;
        name?: string|null;
        avatar?: string|null;
    }

    const loginWithSocials = async (provider: string, requestData: SocialsData) => {
        const data:{ data: { user: IUser, tokens: ITokens } } = await $apiFetch(`/auth/socials/${provider}`, {
            method: "POST",
            body: {
               ...requestData,
                udid: udid.value,
                timezone: timezone.value,
                app_version: appVersion.value,
                platform_name: platformName.value,
                platform_version: platformVersion.value,
            },
        });

        setUser(data.data.user);
        setTokens(data.data.tokens);

        $amplitude.setUserId(data.data.user.uuid)

        return authUser;
    };

    const logout = async () => {
        const data = await $apiFetch("/auth/logout", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${authToken.value}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        setUser(null);
        setTokens(null);

        return data;
    };

    const me = async () => {
        if (authToken.value) {
            try {
                const data = await $apiFetch<{data: IUser}>("/profile", {
                    headers: {
                        'Authorization': `Bearer ${authToken.value}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });

                setUser(data.data);
            } catch (error) {
                setUser(null);
            }
        }

        return authUser;
    }

    interface OttResponse {
        data: {
            token: string
        }
    }

    const getOTT = async (): Promise<OttResponse> => {
        return await $apiFetch("/auth/ott/create", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${authToken.value}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
    }

    const updateProfile = async (data: FormData) => {
        await $apiFetch("/profile/update", {
            method: "POST",
            body: data
        });
    }

    const deleteAccount = async () => {
        await $apiFetch("/profile", {
            method: "DELETE",
            body: {}
        });

        setUser(null);
        setTokens(null);
    }

    const refreshTheToken = async () => {
        if (!refreshToken.value) {
            throw new Error('No refresh token available');
        }

        try {
            const data: {data: {tokens: ITokens}} = await $fetch("/web-api/auth/refresh-token", {
                method: "POST",
                body: { refresh_token: refreshToken.value }
            });

            setTokens(data.data.tokens);

            return data.data.tokens;
        } catch (error) {
            setUser(null);
            setTokens(null);

            return null;
        }
    }

    const openAuthModal = () => {
        if (authUser.value) {
            return;
        }

        isAuthModalShow.value = true;
    }

    const closeAuthModal = () => {
        isAuthModalShow.value = false;
    }

    return {
        authToken,
        user: authUser,
        totalBalance,
        register,
        login,
        loginWithSocials,
        logout,
        me,
        updateProfile,
        deleteAccount,
        isAuthModalShow,
        openAuthModal,
        closeAuthModal,
        refreshTheToken,
        getOTT
    };
};